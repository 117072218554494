//Closure to encapsulate all JS
var STORM,
    UTILS = {
		attributelist: require('storm-attributelist')
	},
    UI = (function(w, d) {
            'use strict';

            var load = function(src, cb) {
                    var t = document.createElement('script'),
                        s = document.getElementsByTagName('script')[0];
                    t.async = true;
                    s.parentNode.insertBefore(t, s);
                    t.onload = cb;
                },
                Toggler = require('storm-toggler'),
				initTogglers = function() {
                    if(!(d.querySelector('.js-toggle'))) { return; } 
					Toggler.init('.js-toggle');
				},
                initMenu = function(){
                    var activeMenuClassName = 'active',
                        btnTemplate = ['<button class="nav__toggle-btn js-toggle-nav" role="button">',
                                        '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">',
                                        '<path fill="none" stroke="#036" stroke-miterlimit="10" d="M13.1 3.7L6.9 10 .8 3.7"></path>',
                                        '</svg></button>'].join(''),
                        toggle = function(e){
                            this.parentNode.className = !!~this.parentNode.className.indexOf(activeMenuClassName) ? this.parentNode.className.split(' ' + activeMenuClassName).join('') : this.parentNode.className + ' ' + activeMenuClassName;
                        },
                        patternLibraryLinkTemplate = '<li class="menu-item"><a class="nav__link" href="/patterns/">Pattern Library</a><button class="nav__toggle-btn"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><path fill="none" stroke="#036" stroke-miterlimit="10" d="M13.1 3.7L6.9 10 .8 3.7"/></svg></button>';
                    
                    [].slice.call(d.querySelectorAll('.sub-menu')).forEach(function(sub){
                        var id = sub.previousElementSibling.textContent.split(' ').join('-').toLowerCase();
                        sub.setAttribute('id', id);
                        sub.insertAdjacentHTML('beforebegin', btnTemplate.split('{{id}}').join(id));
                    });

                    [].slice.call(d.querySelectorAll('.js-toggle-nav')).forEach(function(btn){
                        if(!!~btn.parentNode.className.indexOf('current-page-parent') || !!~btn.parentNode.className.indexOf('current-menu-item')) {
                            btn.parentNode.className = (btn.parentNode.className.split(' current-page-parent').join('').split(' current-menu-item').join('')) + ' ' + activeMenuClassName;
                        }
                        btn.addEventListener('ontouchstart' in window ? 'contouchstart' : 'click', toggle, false);
                    });

                    d.querySelector('.nav-list').lastElementChild.insertAdjacentHTML('afterend', patternLibraryLinkTemplate);
                },
                initPoints = function(){
                    if(!d.querySelector('.point')) { return; }

                    var spanWrapperTemplate = '<span class="point-wrapper">{{point}}</span>';

                    [].slice.call(d.querySelectorAll('.point')).forEach(function(point){
                        var text = point.innerHTML,
                            pointNum = spanWrapperTemplate.split('{{point}}').join(text.charAt(0));
                        point.innerHTML = pointNum + text.substring(1);
                    });
                },
                initExamples = function(){
                    if(!d.querySelector('.example')) { return; }

                    var toggle = function(e){
                            e.target.nextElementSibling.className = !!~e.target.nextElementSibling.className.indexOf('hidden') ? e.target.nextElementSibling.className.split('hidden').join('') : e.target.nextElementSibling.className + ' hidden';
                            e.target.textContent = e.target.textContent === 'Show Example' ? 'Hide Example' : 'Show Example';
                            e.target.className = !!~e.target.className.indexOf('active') ?  e.target.className.split('active').join('') : e.target.className + ' active';
                        };

                    [].slice.call(d.querySelectorAll('.example')).forEach(function(example){
                        example.className += ' hidden';
                        example.insertAdjacentHTML('beforebegin', '<button class="box-trigger">Show Example</button>');
                    });
                    [].slice.call(d.querySelectorAll('.box-trigger')).forEach(function(btn){
                        btn.addEventListener('ontouchstart' in window ? 'touchstart' : 'click', toggle, false);
                    });

                },
                init = function() {
                    initTogglers();
                    initMenu();
                    initPoints();
                    initExamples();

                    /*

                    $('.example').hide();
                    $('.example').before('<button class="box-trigger">Show Example</button>');

                    $('.box-trigger').on('click', function() {
                        $(this).next().toggle();
                        if ($(this).html() === 'Show Example') {
                            $(this).html("Hide Example")
                        } else {
                            $(this).html("Show Example")
                        }
                    });

                    */
                };

            //Interface with/entry point to site JS
            return {
                init: init
            };

        }(window, document, undefined));

global.STORM = STORM = {
    UI: UI,
    UTILS: UTILS
};


//Cut the mustard
//Don't run any JS if the browser can't handle it
if('addEventListener' in window) window.addEventListener('DOMContentLoaded', STORM.UI.init, false);